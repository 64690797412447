import styled from 'styled-components';

type BarProps = {
  duration: number;
};

export const Bar = styled.div<BarProps>`
  height: 100%;
  animation: progressBar ${({ duration }) => duration}s linear;

  @keyframes progressBar {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  background-color: ${({ theme }) => theme.colors.primary};
  border-radius: inherit;
`;

export const Container = styled.div`
  width: 50%;
  height: ${({ theme }) => theme.space.regular};

  background-color: ${({ theme }) => theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.radii.s};
`;
