import { useQuery } from 'react-query';

import { FasterRemoteConfig } from '../models';

export const FALLBACK_PROPERTY_KEY = 'fallbackProperty';

const FALLBACK_PROPERTY = {
  [FALLBACK_PROPERTY_KEY]: false,
};

export const useFasterRemoteConfig = (fasterSchemaName: string, enabled: boolean) => {
  const { isLoading, data } = useQuery(
    ['faster-schemas-values', fasterSchemaName],
    async () => await FasterRemoteConfig.getSchemaValue(fasterSchemaName),
    { enabled, staleTime: Infinity }
  );

  return { schemaValue: data ?? FALLBACK_PROPERTY, isLoading };
};
