import { Drawer as PomodoroDrawer } from "@ifood/pomodoro-components";
import styled from "styled-components";

export const Header = styled(PomodoroDrawer.Header)`
  padding-top: ${({ theme }) => theme.space.large};
  padding-right: ${({ theme }) => theme.space.small};
  padding-bottom: ${({ theme }) => theme.space.large};
  padding-left: ${({ theme }) => theme.space.small};

  & h1 {
    line-height: 1.3;
  }
`;

export const Body = styled(PomodoroDrawer.Body)`
  flex-grow: 1;

  padding: 0;
`;

export const Footer = styled(PomodoroDrawer.Footer)`
  justify-content: center;
`;
