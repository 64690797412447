import { ReactElement, forwardRef } from "react";
import * as S from "./styles";

type CarouselItemProps = {
  gap: string;
  index: number;
  mobileWidth: string;
  children: ReactElement;
  simultaneousItems: number;
};

export const CarouselItem = forwardRef<HTMLDivElement, CarouselItemProps>(
  ({ children, index, simultaneousItems, gap, mobileWidth, ...props }, ref) => {
    return (
      <S.Item
        {...props}
        ref={ref}
        gap={gap}
        mobileWidth={mobileWidth}
        data-index={index}
        simultaneousItems={simultaneousItems}
      >
        {children}
      </S.Item>
    );
  },
);
