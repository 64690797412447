import { HeadingProps, Text as PomodoroText, TextProps as PomodoroTextProps } from '@ifood/pomodoro-components';
import styled from 'styled-components';

type TextProps = Omit<PomodoroTextProps, 'as'> & (Pick<HeadingProps, 'as'> | Pick<PomodoroTextProps, 'as'>);

PomodoroText.propTypes = {};

export const Text = styled(PomodoroText)<TextProps>`
  display: block;
`;
