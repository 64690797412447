/**
 * Determines if we are running on development environment.
 * @return {boolean} true if running on development environment
 */
export function getIsDevelopment() {
  return process.env['NODE_ENV'] === 'development';
}
/**
 * Determines if we are running on test environment.
 * @return {boolean} true if running on test environment
 */
export function getIsTest() {
  return process.env['NODE_ENV'] === 'test';
}

/**
 * Determines if we are running on server or in client.
 * @return {boolean} true if running on server
 */
export function getIsServerRendered() {
  return typeof window === 'undefined';
}
