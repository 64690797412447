import { createGlobalStyle } from 'styled-components';

const globalStyle = createGlobalStyle`
  html {
    box-sizing: border-box;

    font-family: Arial, Helvetica, sans-serif;
  }

  body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  html, 
  body,
  main,
  div#__next {
    height: 100%;
  }

  .grecaptcha-badge { visibility: hidden; }
`;

export default globalStyle;
