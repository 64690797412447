import { Z_INDEXES } from '@whitelabel-webapp/shared/constants';

import { BaseDrawerProps } from '../BaseDrawer';
import * as S from './styles';

export type DrawerWithPushProps = BaseDrawerProps & {
  onClose: () => void;
  title?: React.ReactNode;
  zIndex?: number;
};

export const DrawerWithPush: React.FC<DrawerWithPushProps> = ({
  open,
  title,
  onClose,
  zIndex,
  children,
  ...otherProps
}) => {
  return (
    <S.Drawer
      open={open}
      onClose={onClose}
      placement="bottom"
      zIndex={zIndex ?? Z_INDEXES.DEFAULT_DRAWER_WITH_PUSH}
      {...otherProps}
    >
      <S.Header>
        <S.Push
          onClick={onClose}
          onTouchMove={onClose}
          data-testid="close-drawer-button"
        />
        {title}
      </S.Header>
      <S.Body>{children}</S.Body>
    </S.Drawer>
  );
};
