import styled from 'styled-components';

type ContainerProps = {
  visible: boolean;
};

export const Container = styled.div<ContainerProps>`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.grayLight};
  overflow: hidden;
  transition: box-shadow 100ms ease-in-out;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  z-index: 99;
`;
