import { Heading } from '../Heading';
import * as S from './styles';

type ActionSheetProps = {
  open: boolean;
  title?: string;
  onClose?: () => void;
};

type ActionSheetCompositionElements = {
  Header: typeof S.Header;
  Body: typeof S.Body;
  Footer: typeof S.Footer;
};

type ActionSheetComposition = React.FC<ActionSheetProps> &
  ActionSheetCompositionElements;

export const ActionSheet: ActionSheetComposition = ({
  open,
  title,
  onClose,
  children,
  ...otherProps
}) => {
  return (
    <S.Drawer
      placement="bottom"
      open={open}
      onClose={onClose}
      size="xl"
      {...otherProps}
    >
      {title && (
        <S.Header>
          <Heading as="h4" variant="small">
            {title}
          </Heading>
        </S.Header>
      )}
      {children}
    </S.Drawer>
  );
};

ActionSheet.Header = S.Header;
ActionSheet.Body = S.Body;
ActionSheet.Footer = S.Footer;
