import { formatPhoneToOtp } from "@whitelabel-webapp/shared/string-utils";
import { getParamAndSaveToSession } from "@whitelabel-webapp/shared/url-utils";

import {
  MERCHANT_PHONE_PARAM_KEY,
  MERCHANT_PHONE_SESSION_KEY,
  SOURCE_ID_PARAM_KEY,
  SOURCE_ID_SESSION_KEY,
  USER_ID_PARAM_KEY,
  USER_ID_SESSION_KEY,
  USER_PHONE_PARAM_KEY,
  USER_PHONE_SESSION_KEY,
} from "./constants";

const mountSessionKeyWithMerchantId = (merchantId: string, key: string) =>
  `${merchantId}.${key}`;

export class ExternalIntegration {
  static sourceID?: string;
  static userId?: string;
  static userPhone?: string;
  static merchantPhone?: string;

  static initialize(merchantId: string) {
    ExternalIntegration.sourceID = getParamAndSaveToSession(
      SOURCE_ID_PARAM_KEY,
      mountSessionKeyWithMerchantId(merchantId, SOURCE_ID_SESSION_KEY),
    );
    ExternalIntegration.userId = getParamAndSaveToSession(
      USER_ID_PARAM_KEY,
      mountSessionKeyWithMerchantId(merchantId, USER_ID_SESSION_KEY),
    );
    ExternalIntegration.userPhone = formatPhoneToOtp(
      getParamAndSaveToSession(
        USER_PHONE_PARAM_KEY,
        mountSessionKeyWithMerchantId(merchantId, USER_PHONE_SESSION_KEY),
      ),
    );
    ExternalIntegration.merchantPhone = getParamAndSaveToSession(
      MERCHANT_PHONE_PARAM_KEY,
      mountSessionKeyWithMerchantId(merchantId, MERCHANT_PHONE_SESSION_KEY),
    );
  }
}
