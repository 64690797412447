import * as S from './styles';

export const PoweredBy: React.VFC = () => {
  return (
    <div>
      <S.Text variant="small">Plataforma iFood</S.Text>
      <S.Image src="/icons/hearth-filled.svg" />
      <S.Text variant="small">para lojas parceiras.</S.Text>
    </div>
  );
};
