import { Button } from "@ifood/pomodoro-components";
import { appAboyeur } from "@whitelabel-webapp/shared/config";
import { Page } from "@whitelabel-webapp/shared/page-wrapper";
import {
  getMerchantIdFromUrl,
  getQueryParams,
} from "@whitelabel-webapp/shared/url-utils";
import NextImage from "next/image";
import { useRouter } from "next/router";
import { useCallback, useEffect } from "react";

import * as S from "./styles";

export const Error: React.VFC = () => {
  const router = useRouter();
  const refreshPage = useCallback(() => router.reload(), [router]);

  useEffect(() => {
    appAboyeur.events.pages.view({
      page: "error",
      page_url: window.location.href,
      merchant_id: getMerchantIdFromUrl(),
      coupon: getQueryParams().get("coupon") ?? undefined,
    });
  }, []);

  return (
    <Page title="Algo deu errado por aqui" name="error" noIndex>
      <S.EmptyState
        title="Algo deu errado por aqui"
        description="Aguarde alguns instantes e tente acessar esta página outra vez."
      >
        <NextImage
          src="/images/error.png"
          alt=""
          width={291}
          height={286}
          layout="fixed"
        />
        <Button
          type="button"
          mt={10}
          variant="primary"
          ml="auto"
          mr="auto"
          onClick={refreshPage}
        >
          Atualizar página
        </Button>
      </S.EmptyState>
    </Page>
  );
};
