import { useRef } from 'react';

type UseListOfRefs = [
  React.MutableRefObject<Map<number, Element | null>>,
  (index: number) => (el: HTMLDivElement | null) => void
];

export const useListOfRefs = (): UseListOfRefs => {
  const refs = useRef(new Map<number, Element | null>());

  const addElementToRefs =
    (index: number) =>
    (el: HTMLDivElement | null): void => {
      refs.current.set(index, el);
    };

  return [refs, addElementToRefs];
};
