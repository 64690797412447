import isPropValid from '@emotion/is-prop-valid';

import { Values } from './typeHelpers';

export const pickHTMLProps = <T>(props: T): Record<string, Values<Partial<T>>> =>
  Object.entries(props)
    .filter(([prop]) => isPropValid(prop))
    .reduce((prevHtmlProps: Record<string, Values<Partial<T>>>, [key, value]) => {
      const htmlProps = prevHtmlProps;
      htmlProps[key] = value;
      return htmlProps;
    }, {});
