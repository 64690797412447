import deburr from 'lodash.deburr';

export function isStringEqual(a: string, b: string): boolean {
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
}

export function normalizeAndLowerCase(str: string): string {
  return typeof str === 'string' ? deburr(str).toLowerCase() : '';
}

export const removePhoneMask = (phone: string | number) => {
  if (!phone) return '';

  return String(phone).replace(/\D+/g, '');
};

export const removeCountryCode = (phone: string) => {
  if (!phone) return '';

  if (phone.length <= 11) return phone;

  return phone.replace(/^\+?\d{2}/, '');
};

export const formatPhoneToOtp = (phone: string) => {
  const formattedPhone = removeCountryCode(phone);

  if (formattedPhone.length !== 10) return formattedPhone;

  return `${formattedPhone.slice(0, 2)}9${formattedPhone.slice(2)}`;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
