import styled from 'styled-components';

import { BaseDrawer } from '../BaseDrawer';
import { ButtonClean } from '../ButtonClean';

export const Drawer = styled(BaseDrawer)`
  height: auto;
  max-height: unset;

  margin-bottom: 0 !important;
`;

export const Header = styled(BaseDrawer.Header)`
  text-align: center;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;

  @media screen and (max-width: 767px) {
    padding-top: ${({ theme }) => theme.space.regular};
    padding-bottom: ${({ theme }) => theme.space.none};
  }
`;

export const Push = styled(ButtonClean)`
  width: 100%;
  padding: 5px;
  &::after {
    display: block;
    width: 40px;
    height: 5px;
    margin: 0 auto;
    margin-bottom: 20px;

    background-color: ${(props) => props.theme.colors.grayDarkest};
    border-radius: 3px;

    content: '';
  }
`;

export const Body = styled(BaseDrawer.Body)`
  padding: ${({ theme }) => theme.space.regular};
  overflow-y: auto;
  max-height: 75vh;
`;
