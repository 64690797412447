import { hotjar } from "react-hotjar";

import { ENVS } from "./envs";

const hotjarMapDomains = {
  "loja.ifood.com.br": ENVS.HOTJAR_ID,
} as const;

export function initializeHotjar() {
  if (typeof window === "undefined" || !ENVS.HOTJAR_VERSION) {
    return;
  }

  const host = window.location.host as keyof typeof hotjarMapDomains;
  const id = hotjarMapDomains[host];

  if (!id) return;

  hotjar.initialize(parseInt(id), parseInt(ENVS.HOTJAR_VERSION));
}
