import { Divider, Flex, Icon, snackbar } from "@ifood/pomodoro-components";
import { Copy } from "@ifood/pomodoro-icons";
import React, { useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import {
  FacebookIcon,
  FacebookShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";

import { Text } from "../Text";
import { TITLE } from "./constants";
import { Desktop } from "./Desktop";
import { Mobile } from "./Mobile";
import * as S from "./styles";
import { generateCampaignURL } from "./utils";

type SocialNetworksEvents = "whatsapp" | "telegram" | "facebook" | "twitter";

export type ShareProps = {
  campaignLocationName: string;
  route: string;
  successAboyeurEvent: (metadata: string) => void;
  errorAboyeurEvent: (error: any) => void;
  openAboyeurEvent: () => void;
  closeAboyeurEvent: () => void;
};

type StyledComponentsProps = {
  className?: string;
};

export const Share: React.FC<ShareProps & StyledComponentsProps> = ({
  route: url,
  successAboyeurEvent,
  errorAboyeurEvent,
  openAboyeurEvent,
  closeAboyeurEvent,
  campaignLocationName: campaignName,
  className,
  children,
}) => {
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 765px)" });

  const [origin, setOrigin] = useState("");

  useEffect(() => {
    if (typeof window !== "undefined") {
      setOrigin(window.location.origin);
    }
  }, []);

  const currentURL = useMemo(() => origin + url, [url, origin]);

  function handleCloseShareAction() {
    closeAboyeurEvent();
    setIsShareModalVisible(false);
  }

  function handleOpenShareAction() {
    openAboyeurEvent();
    setIsShareModalVisible(true);
  }

  function handleSocialNetwork(event: SocialNetworksEvents) {
    successAboyeurEvent(event);
    handleCloseShareAction();
  }

  function handleCopyLink() {
    try {
      const textField = document.createElement("textarea");
      textField.innerText =
        currentURL +
        generateCampaignURL(
          currentURL,
          "partner",
          "btshare",
          `btshare_${campaignName}`,
        );
      document.body.appendChild(textField);
      textField.select();
      document.execCommand("copy");
      textField.remove();

      snackbar({
        variant: "success",
        message: "Link copiado",
      });

      successAboyeurEvent("copy_link");
    } catch (error: any) {
      const message =
        error.message || "Algo deu errado!, não foi possível copiar o link.";

      snackbar({
        variant: "error",
        message: message,
      });

      errorAboyeurEvent(error);
    }

    handleCloseShareAction();
    return;
  }

  function renderContent() {
    return (
      <Flex flexDirection="column">
        <S.SocialNetworks isMobile={isMobile}>
          <S.SocialNetwork>
            <WhatsappShareButton
              url={currentURL}
              onClick={() => handleSocialNetwork("whatsapp")}
            >
              <WhatsappIcon round={true} size={36} />
              <Text variant="small">WhatsApp</Text>
            </WhatsappShareButton>
          </S.SocialNetwork>
          <S.SocialNetwork>
            <TelegramShareButton
              url={currentURL}
              onClick={() => handleSocialNetwork("telegram")}
            >
              <TelegramIcon round={true} size={36} />
              <Text variant="small">Telegram</Text>
            </TelegramShareButton>
          </S.SocialNetwork>
          <S.SocialNetwork>
            <FacebookShareButton
              url={currentURL}
              onClick={() => handleSocialNetwork("facebook")}
            >
              <FacebookIcon round={true} size={36} />
              <Text variant="small">Facebook</Text>
            </FacebookShareButton>
          </S.SocialNetwork>
          <S.SocialNetwork>
            <TwitterShareButton
              url={currentURL}
              onClick={() => handleSocialNetwork("twitter")}
            >
              <TwitterIcon round={true} size={36} />
              <Text variant="small">Twitter</Text>
            </TwitterShareButton>
          </S.SocialNetwork>
        </S.SocialNetworks>
        <Divider my={16} mx={0} />
        <S.CopyLink as="button" onClick={handleCopyLink}>
          <Icon component={Copy} size="xs" />
          Copiar link
        </S.CopyLink>
      </Flex>
    );
  }

  return (
    <>
      <S.Container
        as="button"
        mb="small"
        onClick={handleOpenShareAction}
        className={className}
      >
        {children}
      </S.Container>
      {isMobile && (
        <Mobile
          isOpen={isShareModalVisible}
          title={TITLE}
          onClose={handleCloseShareAction}
        >
          {renderContent()}
        </Mobile>
      )}
      {!isMobile && (
        <Desktop
          isOpen={isShareModalVisible}
          title={TITLE}
          onClose={handleCloseShareAction}
        >
          {renderContent()}
        </Desktop>
      )}
    </>
  );
};
