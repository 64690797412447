import { useState } from 'react';

export function useAsyncState<State>(initialState: State) {
  const [state, setState] = useState(initialState);

  const getState = async () => {
    let saveState = state;

    await setState((currentState) => {
      saveState = currentState;

      return currentState;
    });

    return saveState;
  };

  return [getState, setState] as [typeof getState, typeof setState];
}
