import { useCallback, useEffect, useState } from 'react';

type CopyStatus = 'inactive' | 'copied' | 'failed';
type useCopyToClipboardReturn = { browserSupports: boolean; status: CopyStatus; copy: () => void };

export const useCopyToClipboard = (text: string, notifyTimeout = 3000): useCopyToClipboardReturn => {
  const [copyStatus, setCopyStatus] = useState<CopyStatus>('inactive');
  const [browserSupports, setBrowserSupports] = useState<boolean>(true);

  const copy = useCallback(async () => {
    try {
      await navigator.clipboard.writeText(text);

      setCopyStatus('copied');
    } catch (error: any) {
      if (error.name === 'NotAllowedError') {
        return setBrowserSupports(false);
      }

      setCopyStatus('failed');
    }
  }, [text]);

  useEffect(() => {
    if (copyStatus === 'inactive') {
      return;
    }

    const timeoutId = setTimeout(() => setCopyStatus('inactive'), notifyTimeout);

    return () => clearTimeout(timeoutId);
  }, [copyStatus, notifyTimeout]);

  return { browserSupports, status: copyStatus, copy };
};
