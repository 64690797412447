import { FlexProps, Flex as PomodoroFlex } from '@ifood/pomodoro-components';
import styled, { CSSProperties } from 'styled-components';
import { system } from 'styled-system';

const gap = system({
  gap: { property: 'gap', scale: 'space' },
});

export const Flex = styled(PomodoroFlex)<FlexProps & { gap?: CSSProperties['gap'] }>`
  ${gap}
`;
