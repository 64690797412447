import styled from "styled-components";

export const Button = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.space.smaller};

  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ theme }) => theme.space.largest};
  height: ${({ theme }) => theme.space.largest};

  background-color: unset;
  border: none;
  cursor: pointer;

  & svg {
    color: white;
  }
`;
