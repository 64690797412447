export const SOURCE_ID_SESSION_KEY = 'source_id_session_key';
export const SOURCE_ID_PARAM_KEY = 'source_id';

export const USER_ID_SESSION_KEY = 'source_user_id_session_key';
export const USER_ID_PARAM_KEY = 'source_user_id';

export const USER_PHONE_SESSION_KEY = 'user_phone_id_session_key';
export const USER_PHONE_PARAM_KEY = 'user_phone';

export const MERCHANT_PHONE_SESSION_KEY = 'merchant_phone_session_key';
export const MERCHANT_PHONE_PARAM_KEY = 'merchant_phone';
