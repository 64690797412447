import { Icon } from "@ifood/pomodoro-components";
import styled from "styled-components";
import { Text } from "../../../Text";

type QuickAddProps = {
  isWeightVariable?: boolean;
};

export const QuickAddContainer = styled.div`
  margin: 0;
  height: 40px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 50px;
`;

export const Value = styled(Text)<QuickAddProps>`
  height: 30px;
  min-width: 25px;
  line-height: 30px;
  text-align: center;
  font-weight: 500;
  margin: 3px 0;
  margin: 0 -6px;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ isWeightVariable }) => (isWeightVariable ? "12px" : "16px")};
`;

export const CustomIcon = styled(Icon)`
  cursor: pointer;
  padding: 0 6px;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    scale: 1.2;
    transition: all 100ms;
  }

  svg path {
    stroke-width: 2px;
    stroke: ${({ theme }) => theme.colors.primary};
  }
`;
