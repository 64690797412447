import { Flex, Grid } from '@ifood/pomodoro-components';
import styled from 'styled-components';

type SocialNetworkProps = {
  isMobile: boolean;
};

export const Container = styled(Flex)`
  gap: 12px;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.space.none};

  background: none;
  border: none;
  cursor: pointer;

  -webkit-appearance: none;
`;

export const SocialNetworks = styled(Grid)<SocialNetworkProps>`
  grid-row-gap: 10px;
  grid-column-gap: ${({ isMobile }) => (isMobile ? 'auto' : '50px')};
  grid-template-columns: 1fr 1fr 1fr 1fr;
`;

export const SocialNetwork = styled(Flex)`
  align-items: center;
  justify-content: center;
`;

export const CopyLink = styled(Flex)`
  gap: 9px;
  align-items: center;
  align-self: center;
  justify-content: center;
  margin-bottom: 16px;

  color: ${({ theme }) => theme.colors.primary};
  font-weight: 200;
  font-size: ${({ theme }) => theme.fontSizes.s};
  font-family: ${({ theme }) => theme.fonts.primary};

  background: none;
  border: none;
  cursor: pointer;

  -webkit-appearance: none;
  appearance: none;
`;
