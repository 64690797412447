import { Text as PomodoroText } from '@ifood/pomodoro-components';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const Image = styled(SVG)`
  margin: 0px 3px 0px 3px;
`;

export const Text = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.grayDarkest};
`;
