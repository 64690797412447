import { ENVS } from "./envs";
export const groceriesApiBffURL = ENVS.GROCERIES_API_BFF_URL;
export const imageServiceURL = ENVS.STATIC_IMAGES_URL;
export const posWaitingPageBffURL = ENVS.POS_WAITING_PAGE_BFF_URL;

export enum Channel {
  IFOOD = "IFOOD",
}

export enum Context {
  WHITELABEL = "WHITELABEL",
}
