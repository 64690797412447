export enum KEY_MAP {
  ESCAPE = "Escape",
  ENTER = "Enter",
  UP = "ArrowUp",
  DOWN = "ArrowDown",
  LEFT = "ArrowLeft",
  RIGHT = "ArrowRight",
  SPACE = "Space",
  TAB = "Tab",
  SHIFT = "Shift",
  CTRL = "Control",
}

export enum Z_INDEXES {
  DEFAULT = 0,
  TEXT_OVER_COVER = 1,
  BOTTOM_FLOATING_STACK = 2,
  BOTTOM_FLOATING_STACK_WITH_PRIORITY = 202,
  DEFAULT_DRAWER = 201,
  DEFAULT_DRAWER_WITH_PUSH = BOTTOM_FLOATING_STACK_WITH_PRIORITY + 1,
  CHECKOUT_DRAWER = DEFAULT_DRAWER + 1,
  ITEM_DETAILS_DRAWER = CHECKOUT_DRAWER + 1,
  ADDRESS_DRAWER = ITEM_DETAILS_DRAWER + 1,
  INDOOR_ADDRESS_COMPLEMENT_DRAWER = ITEM_DETAILS_DRAWER + 1,
  PAYMENT_DRAWER = CHECKOUT_DRAWER + 1 + BOTTOM_FLOATING_STACK_WITH_PRIORITY,
  AUTHENTICATION_DRAWER = PAYMENT_DRAWER + 1,
  DONT_RECEIVED_CODE_DRAWER = AUTHENTICATION_DRAWER +
    1 +
    BOTTOM_FLOATING_STACK_WITH_PRIORITY,
  RESEND_CODE_DRAWER = AUTHENTICATION_DRAWER +
    1 +
    BOTTOM_FLOATING_STACK_WITH_PRIORITY,
  TAKEOUT_ADDRESS_DRAWER = ITEM_DETAILS_DRAWER +
    1 +
    BOTTOM_FLOATING_STACK_WITH_PRIORITY,
  HELP_DIALOG = DEFAULT_DRAWER + 1 + BOTTOM_FLOATING_STACK_WITH_PRIORITY,
  PROFILE_DRAWER = CHECKOUT_DRAWER + 1,
  PWA_MODAL_INSTALLATION = BOTTOM_FLOATING_STACK_WITH_PRIORITY + 1,
  CONFIRM_ORDER_DRAWER = CHECKOUT_DRAWER +
    1 +
    BOTTOM_FLOATING_STACK_WITH_PRIORITY,
}
