import NextImage from 'next/image';
import React, { useState } from 'react';

type ImageWithFallbackProps = {
  src: string;
  fallbackSrc: string;
  // All other props
  [x: string]: unknown;
};

export const ImageWithFallback: React.VFC<ImageWithFallbackProps> = ({ src, fallbackSrc, ...otherProps }) => {
  const [imgSrc, setImgSrc] = useState(src || fallbackSrc);

  function handleError() {
    setImgSrc(fallbackSrc);
  }

  return <NextImage {...otherProps} src={imgSrc} onError={handleError} />;
};
