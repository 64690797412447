import { Close } from '@ifood/pomodoro-icons';

import { ActionSheet } from '../../ActionSheet';
import { Heading } from '../../Heading';
import * as S from './styles';

type MobileProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

export const Mobile: React.FC<MobileProps> = ({ isOpen, title, onClose, children }) => {
  return (
    <ActionSheet open={isOpen}>
      <S.Header>
        <Heading as="h4" variant="small" fontWeight="bold">
          {title}
        </Heading>
        <S.Icon component={Close} size="s" onClick={onClose} />
      </S.Header>
      <ActionSheet.Body>{children}</ActionSheet.Body>
    </ActionSheet>
  );
};
