import styled from "styled-components";
import { DefaultTheme } from "styled-components";

const desktop = ({ theme }: { theme: DefaultTheme }) =>
  `min-width: ${theme.screens.l.min}`;

export const Item = styled.div<{
  gap: string;
  simultaneousItems: number;
  mobileWidth: string;
}>`
  display: flex;
  position: relative;
  min-width: ${({ simultaneousItems, mobileWidth }) =>
    simultaneousItems > 1 ? mobileWidth : "100%"};
  max-width: ${({ simultaneousItems, mobileWidth }) =>
    simultaneousItems > 1 ? mobileWidth : "100%"};

  @media (${desktop}) {
    --simultaneousItems: ${({ simultaneousItems }) => simultaneousItems};
    --gap: ${({ gap }) => gap};

    --width: calc(
      (100% - var(--gap) * (var(--simultaneousItems) - 1)) /
        var(--simultaneousItems)
    );

    min-width: var(--width);
    max-width: var(--width);
  }
`;
