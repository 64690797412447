import { IncomingMessage } from "http";

import { NextApiRequestCookies } from "next/dist/server/api-utils";
import Cookies, { CookieGetOptions, CookieSetOptions } from "universal-cookie";

export class CookiesStorage {
  static cookies: Cookies;

  static initClientInstance() {
    CookiesStorage.cookies = new Cookies();
  }

  static initServerInstance(
    req: IncomingMessage & { cookies: NextApiRequestCookies },
  ) {
    CookiesStorage.cookies = new Cookies(req.headers.cookie);
  }

  static get(name: string, options?: CookieGetOptions) {
    if (!CookiesStorage.cookies) {
      CookiesStorage.initClientInstance();
    }

    return CookiesStorage.cookies.get(name, options);
  }

  static getAll() {
    if (!CookiesStorage.cookies) {
      CookiesStorage.initClientInstance();
    }

    return CookiesStorage.cookies.getAll();
  }

  static delete(name: string, options?: CookieSetOptions) {
    if (!CookiesStorage.cookies) {
      CookiesStorage.initClientInstance();
    }

    CookiesStorage.cookies.remove(name, options);
  }

  static set(name: string, value: any, options: CookieSetOptions) {
    if (!CookiesStorage.cookies) {
      CookiesStorage.initClientInstance();
    }

    CookiesStorage.cookies.set(name, value, options);
  }
}
