export function getAllCampaignPathParameter() {
  const url = new URL(window.location.href);
  const parameters = new URLSearchParams(url.search.slice(1));

  const source = parameters.get("utm_source");
  const medium = parameters.get("utm_medium");
  const name = parameters.get("utm_campaign");

  return generateCampaignURL(
    window.location.origin + window.location.pathname,
    source || "",
    medium || "",
    name || "",
  );
}

export function generateCampaignURL(
  url: string,
  source?: string,
  medium?: string,
  name?: string,
) {
  const campaignURL = new URL(url);

  if (source) {
    campaignURL.searchParams.append("utm_source", source);
  }

  if (medium) {
    campaignURL.searchParams.append("utm_medium", medium);
  }

  if (name) {
    campaignURL.searchParams.append("utm_campaign", name);
  }

  const queryParameters = campaignURL.search.slice(1);

  return queryParameters ? `?${queryParameters}` : "";
}

export function generateURLwithQueryParameter(
  url: string,
  key: string,
  value: string,
) {
  let newUrl = url;

  if (newUrl.indexOf("?") !== -1) {
    newUrl += "&";
  } else {
    newUrl += "?";
  }

  return `${newUrl}${key}=${value}`;
}

export function getQueryParams() {
  const params = new URLSearchParams(window.location.search);

  return params;
}

export function isOwnDomain(hostname = window.location.hostname) {
  return false;
  const ifoodDomainsRegexp = /(cardapiodigital|ifood).(io|com)(.+)?/;
  return !ifoodDomainsRegexp.test(hostname);
}

export function getDomainWithoutSubdomain(hostname = window.location.hostname) {
  const domainRegexp = /(\w+.\w{2,3}.(\w{2,3})?$)/;
  return hostname.match(domainRegexp)?.[0];
}

export function getParamAndSaveToSession(paramKey: string, sessionKey: string) {
  const valueFromParam = getQueryParams().get(paramKey);

  if (valueFromParam) {
    sessionStorage.setItem(sessionKey, valueFromParam);

    return valueFromParam;
  }

  const valueFromSession = sessionStorage.getItem(sessionKey);

  return valueFromSession || "";
}

export const getMerchantIdFromUrl = (): string => {
  try {
    const uuidRegex =
      /[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/i;
    const match = location.pathname.match(uuidRegex);
    return match ? match[0] : "";
  } catch (_e) {
    return "";
  }
};

export const getOriginAreaFromUrl = (): string => {
  try {
    const currentUrl = location.href;
    if (currentUrl.includes("checkoutStatus=OPEN")) return "cart";
    if (currentUrl.includes("categoria")) return "category_page";
    if (currentUrl.includes("item")) return "item_page";
    if (currentUrl.includes("busca")) return "search_results";
    if (currentUrl.includes("corredor")) return "aisle_page";
    else return "home";
  } catch (_e) {
    return "";
  }
};

export const getAccessPointFromUrl = (): string => {
  try {
    const originArea = getOriginAreaFromUrl();
    if (originArea === "cart") return "cart_item";
    if (originArea === "item_page") return "item_details";
    if (originArea === "category_page") return "grid_item";
    if (originArea === "search_results") return "grid_item";
    if (originArea === "aisle_page") return "grid_item";
    if (originArea === "home") return "grid_item";
    else return "grid_item";
  } catch (_e) {
    return "";
  }
};
