import { Flex } from "@whitelabel-webapp/shared/design-system";
import styled, { DefaultTheme } from "styled-components";

type Themed = { theme: DefaultTheme };

const desktop = ({ theme }: Themed) => `min-width: ${theme.screens.l.min}`;
const belowDesktop = ({ theme }: Themed) => `max-width: ${theme.screens.m.max}`;

export const Scroller = styled(Flex)`
  gap: ${({ gap }) => gap};

  &::-webkit-scrollbar {
    display: none;
  }

  @media (${belowDesktop}) {
    overflow-x: auto;
    overflow-y: hidden;
    gap: ${({ theme }) => theme.space.small};
    padding: 0 ${({ theme }) => theme.space.regular};
  }
`;

export const NavigationButton = styled.button`
  --size: 32px;
  top: calc(50% - var(--size) / 2);

  @media (${belowDesktop}) {
    display: none;
  }

  position: absolute;
  height: var(--size);
  width: var(--size);
  background: none;

  svg {
    height: var(--size);
    width: var(--size);
  }

  padding: 0;
  border: none;
  cursor: pointer;
`;

export const NavigationLeftButton = styled(NavigationButton)`
  left: -${({ theme }) => theme.space.largest};
  transform: rotate(180deg);
`;

export const NavigationRightButton = styled(NavigationButton)`
  right: -${({ theme }) => theme.space.largest};
`;

export const Anchor = styled.div`
  position: relative;
`;

export const ScrollerWrapper = styled.div`
  @media (${desktop}) {
    margin: 0;
    overflow: hidden;
  }
`;
