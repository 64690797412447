import { ProductInfo } from "@whitelabel-webapp/catalog/shared/models";

export function formatQuantity(value: number, productInfo?: ProductInfo) {
  if (!productInfo || !productInfo.quantity) return value;
  let calcValue = value * productInfo.quantity;
  let unit = productInfo.unit;

  if (productInfo.unit === "g" && calcValue >= 1000) {
    calcValue = calcValue / 1000;
    unit = "kg";
  }

  return `${calcValue}${unit}`;
}
