import { PomodoroProvider } from "@ifood/pomodoro-components";
import { ChainResponse } from "@whitelabel-webapp/chain/shared/models";
import {
  generateChainManifestURL,
  generateMerchantManifestURL,
} from "@whitelabel-webapp/merchant/shared/manifest-generator";
import { MerchantResponse } from "@whitelabel-webapp/merchant/shared/models";
import { ENVS, appAboyeur } from "@whitelabel-webapp/shared/config";
import { CHAIN_LOGO_URL } from "@whitelabel-webapp/shared/constants";
import {
  ColorsTheme,
  createVariations,
  neutralTheme,
} from "@whitelabel-webapp/shared/design-system";
import {
  Folders,
  Sizes,
  getImageURL,
} from "@whitelabel-webapp/shared/image-utils";
import { EmptyProps } from "@whitelabel-webapp/shared/types";
import {
  getMerchantIdFromUrl,
  getQueryParams,
} from "@whitelabel-webapp/shared/url-utils";
import isEmpty from "lodash.isempty";
import dynamic from "next/dynamic";
import Head from "next/head";
import Script from "next/script";
import { useEffect, useMemo } from "react";
import styled from "styled-components";

const CanaryFlag = dynamic<EmptyProps>(() =>
  import("./CanaryFlag").then(({ CanaryFlag }) => CanaryFlag),
);

function getColorsTheme(
  defaultColors: ColorsTheme,
  chain?: ChainResponse,
  merchant?: MerchantResponse,
): ColorsTheme {
  if (chain) {
    return {
      primary: chain.primaryColor || defaultColors.primary,
      headings: chain.primaryColor || defaultColors.primary,
    };
  }

  if (merchant) {
    return {
      primary:
        merchant.merchantWhitelabelConfigs.chain.primaryColor ||
        defaultColors.primary,
      headings:
        merchant.merchantWhitelabelConfigs.chain.primaryColor ||
        defaultColors.headings,
    };
  }

  return defaultColors;
}

function configureStorage() {
  const versionHash = "0d93a447-1a38-4867-93e9-94cb0fd8a275";

  if (!localStorage) return;

  if (localStorage.getItem("versionHash") !== versionHash) {
    localStorage.clear();
    localStorage.setItem("versionHash", versionHash);
  }
}

type PageProps = {
  title: string;
  name: string;
  description?: string;
  chainResponse?: ChainResponse;
  merchantResponse?: MerchantResponse;
  merchantPath?: string;
  noIndex?: boolean;
  isGoogleRecaptchaEnabled?: boolean;
  isCanaryModeEnabled?: boolean;
  backgroundColor?: string;
};

export const Main = styled.main<{ backgroundColor: string }>`
  min-height: 100dvh;
  height: auto;
  display: flex;
  flex-direction: column;
  background: ${({ backgroundColor }) => backgroundColor};

  footer div {
    background-color: ${({ backgroundColor }) =>
      backgroundColor == "#f7f7f7" ? "white" : "#f7f7f7"};
  }
`;

export const Page: React.FC<PageProps> = ({
  children,
  title,
  name,
  description,
  chainResponse,
  merchantResponse,
  merchantPath,
  noIndex = false,
  isGoogleRecaptchaEnabled = false,
  isCanaryModeEnabled = false,
  backgroundColor = "white",
}) => {
  const manifestURL = useMemo(() => {
    if (!chainResponse && !merchantResponse) return;

    if (!merchantResponse)
      return generateChainManifestURL(chainResponse as ChainResponse);

    return generateMerchantManifestURL(
      merchantResponse as MerchantResponse,
      merchantPath,
    );
  }, [chainResponse, merchantResponse, merchantPath]);

  const theme = useMemo(() => {
    const defaultColorsTheme = {
      primary: (neutralTheme.colors as Record<string, string>)["primary"],
      headings: (neutralTheme.colors as Record<string, string>)["primary"],
    };

    const fontsTheme = {
      // @ts-ignore
      headings: (neutralTheme.font.family.ifood as Record<string, string>)[
        "headings"
      ],
      // @ts-ignore
      body: (neutralTheme.font.family.ifood as Record<string, string>)["body"],
    };

    return createVariations(
      fontsTheme,
      getColorsTheme(defaultColorsTheme, chainResponse, merchantResponse),
    );
  }, [chainResponse, merchantResponse]);

  const faviconURL = useMemo(() => {
    if (!chainResponse && !merchantResponse) return "";

    if (chainResponse) {
      const logoPath = chainResponse.logoUrl;
      if (!logoPath) return "";
      return `${CHAIN_LOGO_URL}/${logoPath}`;
    }

    if (merchantResponse) {
      const logoPath = merchantResponse.merchantWhitelabelConfigs.chain.logoUrl;
      if (!logoPath) return "";
      return `${CHAIN_LOGO_URL}/${logoPath}`;
    }

    return "";
  }, [chainResponse, merchantResponse]);

  const pageTitle = useMemo(() => {
    if (!chainResponse && !merchantResponse) return title;

    if (chainResponse)
      return `${
        (chainResponse as ChainResponse).name ??
        (chainResponse as ChainResponse).slug
      } - ${title}`;

    return `${(merchantResponse as MerchantResponse).name} - ${title}`;
  }, [title, chainResponse, merchantResponse]);

  useEffect(() => {
    appAboyeur.events.pages.view({
      page: name,
      page_url: window.location.href,
      merchant_id: getMerchantIdFromUrl(),
      coupon: getQueryParams().get("coupon") ?? undefined,
    });

    configureStorage();
  }, [name]);

  return (
    <PomodoroProvider theme={theme}>
      <Head>
        <title>{pageTitle}</title>
        {manifestURL && <link rel="manifest" href={manifestURL} />}
        {faviconURL && (
          <>
            <link rel="icon" type="image/png" href={faviconURL} />
            <link rel="apple-touch-icon" href={faviconURL} />
          </>
        )}
        <meta name="theme-color" content="white" />
        <meta property="og:title" content={pageTitle} />
        <meta name="viewport" content="width=device-width,initial-scale=1.0" />
        <meta charSet="UTF-8" />
        {description && (
          <meta
            name="description"
            property="og:description"
            content={description}
          />
        )}
        {noIndex && <meta name="robots" content="noindex" />}
        <meta
          name="google-site-verification"
          content="swwAKwbGgDLmgv4NOhTxiJOvPctMSDTv2tQZOft0olE"
        />
        <meta
          name="google-site-verification"
          content="JYChscDkhUwFF0Yn3jvJ7BYq1HBd6t1LaQS1jM7vITY"
        />
        <meta
          name="facebook-domain-verification"
          content="ajrozxqb8bp38lowjpb4tmd26b5uta"
        />
      </Head>

      {isCanaryModeEnabled && <CanaryFlag />}

      <Main backgroundColor={backgroundColor}>{children}</Main>

      {isGoogleRecaptchaEnabled && (
        <Script
          src={`https://www.google.com/recaptcha/enterprise.js?render=${ENVS.GOOGLE_RECAPTCHA_KEY}`}
        />
      )}
    </PomodoroProvider>
  );
};
