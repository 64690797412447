import { useEffect, useRef, useState } from 'react';

export const useCountdown = (countdownValue: number) => {
  const intervalRef = useRef<number>();
  const [countdownSeconds, setCountdownSeconds] = useState(countdownValue);
  const resetCountdown = () => {
    setCountdownSeconds(countdownValue);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (countdownSeconds > 0) {
        setCountdownSeconds(countdownSeconds - 1);
      } else {
        clearInterval(intervalRef?.current);
      }
    }, 1000) as unknown as number;

    return function unmount() {
      clearInterval(intervalRef?.current);
    };
  }, [countdownSeconds]);

  return { countdownSeconds, resetCountdown };
};
