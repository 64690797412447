import styled from 'styled-components';

export const ButtonClean = styled.button`
  padding: 0;

  background-color: transparent;
  border: none;
  cursor: pointer;

  -webkit-appearance: none;
`;
