export enum KEY_MAP {
  ESCAPE = 'Escape',
  ENTER = 'Enter',
  UP = 'ArrowUp',
  DOWN = 'ArrowDown',
  LEFT = 'ArrowLeft',
  RIGHT = 'ArrowRight',
  SPACE = 'Space',
  TAB = 'Tab',
  SHIFT = 'Shift',
  CTRL = 'Control',
}
