import { Dialog } from '../../Dialog';
import { Heading } from '../../Heading';
import * as S from './styles';

type DesktopProps = {
  isOpen: boolean;
  title: string;
  onClose: () => void;
};

export const Desktop: React.FC<DesktopProps> = ({ isOpen, title, onClose, children, ...rest }) => {
  return (
    <Dialog open={isOpen} onClose={onClose} {...rest}>
      <S.Header>
        <Heading as="h4" variant="small" fontWeight="bold" m="0">
          {title}
        </Heading>
      </S.Header>
      <Dialog.Body>{children}</Dialog.Body>
    </Dialog>
  );
};
