import Color from "color";
import { Theme } from "styled-system";

import { neutralTheme } from "./neutralTheme";

export type FontsTheme = {
  headings: string;
  body: string;
};

export type ColorsTheme = {
  primary: string;
  headings: string;
};

function isNeutralThemeColor(primaryColor: string) {
  return (
    (neutralTheme.colors as Record<string, string>)["primary"] === primaryColor
  );
}

export const createVariations = (
  fontsTheme: FontsTheme,
  colorsTheme: ColorsTheme,
): Theme => {
  const color = Color(colorsTheme.primary);

  const hasMatchWithNeutralThemeColor = isNeutralThemeColor(
    colorsTheme.primary,
  );
  const neutralThemeColors = neutralTheme.colors as Record<string, string>;

  return {
    fonts: {
      primary: fontsTheme.body,
    },
    // @ts-ignore
    font: {
      family: {
        ifood: {
          headings: fontsTheme.headings,
          body: fontsTheme.body,
        },
      },
    },
    colors: {
      primary: colorsTheme.primary,
      primaryDark: hasMatchWithNeutralThemeColor
        ? neutralThemeColors["primaryDark"]
        : color.darken(0.5).hex(),
      primaryLight: hasMatchWithNeutralThemeColor
        ? neutralThemeColors["primaryLight"]
        : color.lighten(0.5).hex(),
      primaryLightest: hasMatchWithNeutralThemeColor
        ? neutralThemeColors["primaryLightest"]
        : color.lighten(0.75).hex(),
      positive: "#1FAD68",
      positiveDarkest: "#27824C",
    },
  };
};
