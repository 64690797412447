import React from 'react';

import * as S from './styles';

type ProgressBarProps = {
  duration: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({ duration }) => {
  return (
    <S.Container>
      <S.Bar duration={duration}></S.Bar>
    </S.Container>
  );
};
