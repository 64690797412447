import { Icon as PomodoroIcon } from '@ifood/pomodoro-components';
import styled from 'styled-components';

import { ActionSheet } from '../../ActionSheet';

export const Header = styled(ActionSheet.Header)`
  justify-content: space-between;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};

  cursor: pointer;
`;
