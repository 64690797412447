/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  FormElement,
  Icon,
  Input,
  Label,
  Listbox,
  ListboxOption,
  Loading,
} from "@ifood/pomodoro-components";
import { SearchOutlined } from "@ifood/pomodoro-icons";
import { pick } from "@styled-system/props";
import { useCombobox } from "downshift";
import PropTypes from "prop-types";
import {
  ChangeEvent,
  Ref,
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import styled from "styled-components";

import { Container } from "./styles";
import { SearchProps } from "./types";
import { pickHTMLProps } from "./utils/pickHTMLProps";
import { useShareForwardedRef } from "./utils/useShareForwardedRef";

const RedIcon = styled(Icon)`
  color: ${(props): string => props.theme.colors.primary};
`;

export const Search = forwardRef<HTMLInputElement, SearchProps>(
  (props: SearchProps, ref: Ref<HTMLInputElement>) => {
    const {
      label,
      options = [],
      value,
      onSearch,
      onChange,
      onSelectOption,
      allowClear,
      error,
      supportText,
      suffixComponent,
      openMenuOnFocus,
      stayOpen,
      isLoading,
      loadingColor,
      ...otherProps
    } = props;
    const innerRef: RefObject<HTMLInputElement> =
      useShareForwardedRef<HTMLInputElement>(ref);

    const onSelectedItemChange = useCallback(
      // @ts-ignore
      ({ selectedItem }) => {
        if (innerRef?.current) {
          innerRef.current.value = selectedItem;
          innerRef.current.dispatchEvent(new Event("input", { bubbles: true }));
        }
        onSelectOption?.(selectedItem);
      },
      [innerRef, onSelectOption],
    );

    const onInputValueChange = useCallback(
      // @ts-ignore
      ({ inputValue }) => {
        onSearch?.(inputValue);
      },
      [onSearch],
    );

    const {
      isOpen,
      reset,
      getInputProps,
      getLabelProps,
      getMenuProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      selectItem,
      openMenu,
      toggleMenu,
    } = useCombobox({
      items: options,
      // @ts-ignore
      onInputValueChange,
      // @ts-ignore
      onSelectedItemChange,
    });
    const [isOpenByFocus, setIsOpenByFocus] = useState(false);
    const [isOpenByParam, setIsOpenByParam] = useState(stayOpen);

    useEffect(() => {
      if (value && options.includes(value)) {
        selectItem(value);
      }
    }, [options, selectItem, value]);

    const handleOnInput = useCallback(
      (e: ChangeEvent<HTMLInputElement>): void => {
        onChange?.(e);
        setIsOpenByParam(false);
        if (!e.target.value) {
          reset();
        }
      },
      [onChange, reset],
    );

    const [referenceElement, setReferenceElement] = useState<
      RefObject<HTMLDivElement> | HTMLDivElement | null | undefined
    >(null);

    const htmlProps = pickHTMLProps(otherProps);
    const styledSystemProps = pick(otherProps);

    return (
      <FormElement {...styledSystemProps} error={error}>
        {label && <Label {...getLabelProps()}>{label}</Label>}
        <div ref={setReferenceElement}>
          <div {...getComboboxProps()}>
            <Input
              {...getInputProps({
                ...htmlProps,
                ref: innerRef,
                onFocus: () => {
                  if (!isOpen && openMenuOnFocus) {
                    setIsOpenByFocus(true);
                    openMenu();
                  }
                  htmlProps.onFocus?.();
                },
                onClick: () => {
                  if (isOpenByFocus) {
                    setIsOpenByFocus(false);
                  } else if (openMenuOnFocus) {
                    toggleMenu();
                  }
                  htmlProps.onClick?.();
                },
                onBlur: () => {
                  if (stayOpen) {
                    setIsOpenByParam(true);
                  }
                  htmlProps.onBlur?.();
                },
              })}
              onInput={handleOnInput}
              value={value}
              allowClear={allowClear}
              suffixComponent={suffixComponent}
              prefixComponent={
                <RedIcon
                  component={SearchOutlined}
                  size="xs"
                  svgProps={{
                    "aria-label": "Buscar",
                  }}
                />
              }
            />
          </div>
          <Container
            visible={isOpen || !!(referenceElement && options && isOpenByParam)}
          >
            {isLoading ? (
              <div style={{ paddingTop: 10 }}>
                <Loading color={loadingColor} variant="medium" />
              </div>
            ) : (
              <Listbox {...getMenuProps()}>
                {isOpen || (referenceElement && options && isOpenByParam)
                  ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    options.map((item: any, index: number) => (
                      <ListboxOption
                        className={highlightedIndex === index ? "selected" : ""}
                        key={`${item}${index}`}
                        {...getItemProps({ item, index })}
                      >
                        {item}
                      </ListboxOption>
                    ))
                  : null}
              </Listbox>
            )}
          </Container>
        </div>
      </FormElement>
    );
  },
);

Search.propTypes = {
  className: PropTypes.string,
  allowClear: PropTypes.bool,
  clearAriaLabel: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string.isRequired),
  onSearch: PropTypes.func,
  onSelectOption: PropTypes.func,
  error: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  showCounter: PropTypes.bool,
  searchIconLabel: PropTypes.string,
};
