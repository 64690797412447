import '@ifood/pomodoro-fonts';

import { ChainResponse } from '@whitelabel-webapp/chain/shared/models';
import { ExternalIntegration } from '@whitelabel-webapp/integration/external-integration';
import { MerchantResponse } from '@whitelabel-webapp/merchant/shared/models';
import { appAboyeur, initializeAboyeur, initializeAxe, initializeHotjar } from '@whitelabel-webapp/shared/config';
import { Error } from '@whitelabel-webapp/shared/error-pages';
import { FasterRemoteConfig } from '@whitelabel-webapp/shared/experiment-utils';
import { AppProps } from 'next/app';
import { Component } from 'react';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';

import GlobalStyle from '@app/styles/global';

type AppState = {
  queryClient: QueryClient;
  gaID: string;
  serverError?: string;
  error?: Error;
};

function getGaID(
  merchantResponse?: MerchantResponse,
  chainResponse?: ChainResponse,
) {
  return (
    merchantResponse?.merchantWhitelabelConfigs.chain.gaId ||
    chainResponse?.gaId ||
    ""
  );
}

class WhitelabelApp extends Component<AppProps, AppState> {
  state: AppState = {
    queryClient: new QueryClient(),
    gaID: getGaID(
      this.props.pageProps.merchantResponse,
      this.props.pageProps.chainResponse,
    ),
    error: this.props.pageProps.serverError
      ? JSON.parse(this.props.pageProps.serverError)
      : undefined,
  };

  componentDidMount() {
    const merchantId = this.props.pageProps.merchantResponse?.uuid;
    initializeAboyeur({
      merchantId,
      googleAnalyticsId: this.state.gaID,
      isTest: false,
      sourceId: ExternalIntegration.sourceID,
      merchantName: this.props.pageProps.merchantResponse?.name,
      chainId:
        this.props.pageProps.chainResponse?.uuid ||
        this.props.pageProps.merchantResponse?.merchantWhitelabelConfigs.chain
          .id,
    });
    initializeHotjar();
    initializeAxe();
    FasterRemoteConfig.initialize();
  }

  componentDidCatch(error: Error) {
    appAboyeur.events.catch.onError(error);

    this.setState({ error });
  }

  render(): JSX.Element {
    const { Component, pageProps } = this.props;
    const hasError = Boolean(this.state.error);

    return (
      <QueryClientProvider client={this.state.queryClient}>
        <Hydrate state={pageProps.dehydratedState}>
          <GlobalStyle />
          {hasError ? <Error /> : <Component {...pageProps} />}
        </Hydrate>
      </QueryClientProvider>
    );
  }
}

export default WhitelabelApp;
