import { EmptyState as PomodoroEmptyState } from "@ifood/pomodoro-components";
import styled from "styled-components";

export const EmptyState = styled(PomodoroEmptyState)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100dvh;
  padding: 0px 42px 0px 42px;

  font-family: ${({ theme }) => theme.fonts.primary};
  text-align: center;

  background-color: ${({ theme }) => theme.colors.grayLight};
`;
