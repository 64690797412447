import { ChainResponse } from '@whitelabel-webapp/chain/shared/models';
import { MerchantResponse } from '@whitelabel-webapp/merchant/shared/models';
import { CHAIN_LOGO_URL } from "@whitelabel-webapp/shared/constants";
import { neutralTheme } from '@whitelabel-webapp/shared/design-system';
import { Folders, Sizes, getImageURL } from '@whitelabel-webapp/shared/image-utils';

type Display = 'window-control-overlay' | 'minimal-ui' | 'standalone' | 'browser';

type Icon = {
  src: string;
  type: string;
  sizes: string;
  purpose?: string;
};

type Manifest = {
  name: string;
  short_name: string;
  description: string;
  scope: string;
  start_url: string;
  background_color: string;
  theme_color: string;
  icons: Icon[];
  display: Display;
  orientation: string;
};

function _createMerchantManifest(
  merchant: MerchantResponse,
  path?: string,
): Manifest {
  const logoPath = merchant.merchantWhitelabelConfigs.chain.logoUrl;
  const logo = `${CHAIN_LOGO_URL}/${logoPath}`;
  const url = `${typeof window !== "undefined" ? window.location.origin : ""}/${
    path ?? merchant.uuid
  }`;

  return {
    name: merchant.name,
    short_name: merchant.name,
    description: merchant.description,
    scope: url,
    start_url: url,
    background_color: "#FFF",
    theme_color: merchant.merchantWhitelabelConfigs.chain.primaryColor,
    icons: logo
      ? [
          {
            src: logo,
            sizes: "300x300",
            type: "image/png",
            purpose: "any maskable",
          },
        ]
      : [],
    display: "standalone",
    orientation: "portrait",
  };
}

export function generateMerchantManifestURL(
  merchant: MerchantResponse,
  customPath?: string,
): string {
  const manifest = _createMerchantManifest(merchant, customPath);
  const manifestString = JSON.stringify(manifest);

  return `data:application/json;charset=utf-8,${encodeURIComponent(
    manifestString,
  )}`;
}

function _createChainManifest(chainResponse: ChainResponse): Manifest {
  const url = `${typeof window !== "undefined" ? window.location.origin : ""}/${
    chainResponse.slug
  }`;
  const logo = `${CHAIN_LOGO_URL}/${chainResponse.logoUrl}`;

  return {
    name: chainResponse.name,
    short_name: chainResponse.name,
    description: "",
    scope: url,
    start_url: url,
    background_color: "#FFF",
    theme_color: chainResponse.primaryColor,
    icons: [
      {
        src: logo,
        sizes: "150x150",
        type: "image/png",
        purpose: "any maskable",
      },
    ],
    display: "standalone",
    orientation: "portrait",
  };
}

export function generateChainManifestURL(chain: ChainResponse): string {
  const manifest = _createChainManifest(chain);
  const manifestString = JSON.stringify(manifest);

  return `data:application/json;charset=utf-8,${encodeURIComponent(manifestString)}`;
}
