import { Placement, Size } from "@ifood/pomodoro-components/dist/Drawer/types";
import { Z_INDEXES } from "@whitelabel-webapp/shared/constants";

import * as S from "./styles";
import { Drawer } from "@ifood/pomodoro-components";

export type BaseDrawerProps = {
  open: boolean;
  placement?: Placement;
  zIndex?: number;
  // All other props
  [x: string]: any;
};

type BaseDrawerCompositionElements = {
  Header: typeof S.Header;
  Body: typeof S.Body;
  Footer: typeof S.Footer;
};

type BaseDrawerComposition = React.FC<BaseDrawerProps> &
  BaseDrawerCompositionElements;

export const BaseDrawer: BaseDrawerComposition = ({
  open,
  children,
  placement,
  onClose,
  zIndex,
  ...otherProps
}) => (
  <Drawer
    open={open}
    placement={placement}
    size={"m" as Size}
    zIndex={zIndex ?? Z_INDEXES.DEFAULT_DRAWER}
    onClose={onClose}
    {...otherProps}
  >
    {children}
  </Drawer>
);

BaseDrawer.Header = S.Header;
BaseDrawer.Body = S.Body;
BaseDrawer.Footer = S.Footer;
