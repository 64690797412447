import { Dialog } from '@ifood/pomodoro-components';
import styled from 'styled-components';

export const Header = styled(Dialog.Header)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: ${({ theme }) => theme.space.larger};
  padding-right: 56px;
`;

type ContainerProps = {
  onKeyUp: (e: React.KeyboardEvent<HTMLDivElement>) => void;
  role: string;
  style?: React.CSSProperties;
};

export const Container = styled(Dialog)<ContainerProps>`
  width: initial;
  max-width: 90%;
  height: initial;

  border-radius: ${({ theme }) => theme.radii.m};
`;
