import { Flex, Text as PomodoroText } from '@ifood/pomodoro-components';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  align-items: center;
`;

export const Image = styled(SVG)`
  margin: ${({ theme }) => `${theme.space.none} ${theme.space.smallest} ${theme.space.none} ${theme.space.smallest}`};
`;

export const Text = styled(PomodoroText)`
  color: ${({ theme }) => theme.colors.grayDarkest};
`;
