/* eslint-disable @typescript-eslint/no-var-requires */
// import React from 'react';

// import { getIsDevelopment, getIsServerRendered } from './utils';

export function initializeAxe() {
  // if (getIsDevelopment() && !getIsServerRendered()) {
  //   const ReactDOM = require('react-dom');
  //   const axe = require('@axe-core/react');
  //   axe(React, ReactDOM, 1000);
  // }
}
