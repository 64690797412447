import React, { useEffect, useRef } from 'react';

import { KEY_MAP } from './enums';

export type UseEscapeToCloseHook = {
  elementToFocusRef: React.RefObject<HTMLDivElement>;
  onKeyDown: (e: React.KeyboardEvent<HTMLDivElement>) => void;
};

export const useEscapeToClose = (
  open: boolean,
  onClose?: (e?: React.KeyboardEvent<HTMLDivElement>) => void
): UseEscapeToCloseHook => {
  const elementToFocusRef = useRef<HTMLDivElement>(null);
  const previousFocusedElement = useRef<HTMLElement | null>(null);

  useEffect(() => {
    if (open) {
      previousFocusedElement.current = document.activeElement as HTMLElement;

      elementToFocusRef.current?.focus();
    } else {
      previousFocusedElement.current?.focus();
    }
  }, [open]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === KEY_MAP.ESCAPE && onClose) {
      onClose(e);
    }
  };

  return {
    elementToFocusRef,
    onKeyDown,
  };
};
