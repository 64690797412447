import { Flex, Icon as PomodoroIcon } from '@ifood/pomodoro-components';
import styled from 'styled-components';

export const Wrapper = styled(Flex)`
  padding: 0px 8px 0px 8px;
  background-color: ${({ theme }) => theme.colors.grayLight};
  color: ${({ theme }) => theme.colors.grayDarkest};
  font-size: ${({ theme }) => theme.fontSizes.xs};
  justify-content: space-between;
`;

export const Icon = styled(PomodoroIcon)`
  color: ${({ theme }) => theme.colors.primary};
`;
