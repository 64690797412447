import currency from 'currency.js';

export type MoneyJSON = {
  c: currency;
};
export class Money {
  static add(a: Money, b: Money): Money {
    return a.add(b);
  }
  static sub(a: Money, b: Money): Money {
    return a.sub(b);
  }
  static multiply(a: Money, b: number): Money {
    return a.multiply(b);
  }
  static divide(a: Money, b: number): Money {
    return a.divide(b);
  }

  static fromJSON({ c }: MoneyJSON) {
    return new Money(c);
  }

  private c: currency;

  public currency = 'BRL';

  constructor(amount: number | currency | string) {
    if (amount instanceof currency) {
      this.c = amount;
    } else {
      this.c = currency(amount, {
        symbol: 'R$ ',
        separator: '.',
        decimal: ',',
        precision: 2,
      });
    }
  }

  toJSON(): MoneyJSON {
    return {
      c: this.c,
    };
  }

  getValue(): number {
    return this.c.value;
  }

  toString(): string {
    return this.format();
  }

  format(): string {
    return this.c.format();
  }

  cents(): number {
    return this.c.cents();
  }

  multiply(n: number): Money {
    return new Money(this.c.multiply(n));
  }
  divide(n: number): Money {
    return new Money(this.c.divide(n));
  }
  add(n: Money): Money {
    return new Money(this.c.add(n.c));
  }
  sub(n: Money): Money {
    return new Money(this.c.subtract(n.c));
  }
}
