import { Dialog as PomodoroDialog } from '@ifood/pomodoro-components';

import * as S from './styles';
import { useEscapeToClose } from './useEscapeToClose';

export type DialogProps = {
  open: boolean;
  zIndex?: number;
  handleClose?: () => void;
  onClose?: () => void;
  style?: React.CSSProperties;
};

type DialogCompositionElements = {
  Header: typeof S.Header;
  Body: typeof PomodoroDialog.Body;
  Footer: typeof PomodoroDialog.Footer;
};

type DialogComposition = React.FC<DialogProps> & DialogCompositionElements;

export const Dialog: DialogComposition = ({ open, handleClose, style, zIndex, ...otherProps }) => {
  const { onKeyDown } = useEscapeToClose(open, handleClose);

  return (
    <S.Container role="dialog" onKeyUp={onKeyDown} open={open} zIndexCustom={zIndex} {...otherProps} style={style} />
  );
};

Dialog.Header = S.Header;
Dialog.Body = PomodoroDialog.Body;
Dialog.Footer = PomodoroDialog.Footer;
