import { FlexProps } from '@ifood/pomodoro-components';
import { ChevronDown, ChevronUp } from '@ifood/pomodoro-icons';
import { useState } from 'react';

import * as S from './styles';

type DropdownProps = {
  onOpen?: () => void;
} & FlexProps;

export const Dropdown: React.FC<DropdownProps> = ({ onOpen, children, ...otherProps }) => {
  const [isOpen, setIsOpen] = useState(false);

  function handleChangeOpenState() {
    if (isOpen) return setIsOpen(false);

    setIsOpen(true);
    onOpen?.();
  }

  return (
    <S.Wrapper {...otherProps}>
      {children}
      {!isOpen && onOpen && <S.Icon size="s" component={ChevronDown} onClick={handleChangeOpenState} />}
      {isOpen && onOpen && <S.Icon size="s" component={ChevronUp} onClick={handleChangeOpenState} />}
    </S.Wrapper>
  );
};
