export function generateCampaignURL(url: string, source?: string, medium?: string, name?: string) {
  const campaignURL = new URL(url);

  if (source) {
    campaignURL.searchParams.append('utm_source', source);
  }

  if (medium) {
    campaignURL.searchParams.append('utm_medium', medium);
  }

  if (name) {
    campaignURL.searchParams.append('utm_campaign', name);
  }

  const queryParameters = campaignURL.search.slice(1);

  return queryParameters ? `?${queryParameters}` : '';
}
